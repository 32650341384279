/**
 * 请求基类，公共方法封装及导出
 * 请求函数库, 拦截请求封装标准返回处理
 */
import axios from 'axios'
import router from '../router'
import store from '../store'
import WhiteList from './whiteList'
import { api } from '@/utils/config'
import { Message } from 'element-ui';

// axios请求封装
const timerObj = {}
const requestObj = {}
export function baseRequest(baseUrl, url, method, params, head = {}, onUploadProgress, timeout, extraSymbol) {
const cancelKey = extraSymbol ? url + extraSymbol : url
// extraSymbol为同时发两次请求时的判断标记，拼接上url代表同时发的两次请求为不同的请求不会影响上一次请求的取消
// 执行requestObj[url]方法，取消上一次的http请求
if (extraSymbol && requestObj[cancelKey]) {
    requestObj[cancelKey]({ cancel: true })
}
// 简单实现了一个接口请求防抖
const timerKey = url + JSON.stringify(params)
if ((timerObj[timerKey] && !WhiteList.includes(url))) {
    return new Promise((resolve, reject) => {
    console.log(`${timerKey},'请勿重复请求'`)
    reject({ repeat: true }) // eslint-disable-line
    })
}
timerObj[timerKey] = true
let requestTimer = null
requestTimer = setTimeout(() => {
    timerObj[timerKey] = false
}, 10 * 1000)

// 传入一个req_time_sequence参数做返回数据校验
store.commit('addURLKey', url)
if (params) {
    params.req_time_sequence = url + '$$' + store.state.request.urlKey[url]
} else {
    params = {
    req_time_sequence: url + '$$' + store.state.request.urlKey[url]
    }
}
const headers = {
    ...head
}
const [_params, _data] = method === 'GET' ? [params, ''] : ['', params]
return new Promise((resolve, reject) => {
    // let token = window.$cookies.get('token')
    axios({
			withCredentials: true,
			headers,
			timeout: timeout || 3 * 60 * 1000,
			method,
			url: baseUrl + url,
			params: _params,
			data: _data,
			onUploadProgress: onUploadProgress,
			...(extraSymbol && {
					cancelToken: new axios.CancelToken(function executor(c) {
					// executor 函数接收一个 cancel 函数作为参数
					requestObj[cancelKey] = c
					})
			})
    })
    .then(res => {
      if (extraSymbol) requestObj[cancelKey] = null
      timerObj[timerKey] = false
			if (requestTimer) {
				clearTimeout(requestTimer)
			}
			const _res = res.data
			if (_res.code !== 0) {
				performance.clearMarks()
				performance.clearMeasures()
			}
			if (_res.code === 999) {
				window.$cookies.remove('logStatus')
				window.$cookies.remove('logInfo')
				Message.error('Please log in to your account first')
			} else {
				resolve(_res)
			}
    })
    .catch(e => {
			performance.clearMarks()
			performance.clearMeasures()
			if (!e.__CANCEL__ && extraSymbol) {
			requestObj[cancelKey] = null
			}
			timerObj[timerKey] = false
			if (requestTimer) {
				clearTimeout(requestTimer)
			}
			reject(e)
    })
	})
}