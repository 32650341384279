import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/utils/rem.js'
import Confirm from "./components/pub_components/confirm/index.js";
// 导出并自动挂载api的方法到Vue原型链
import _ from '@/api/install'
// import Message from './components/pub_components/message/index.js';
import { Message } from 'element-ui';
import '@/assets/scss/global.scss'


Vue.use(Element);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = Confirm;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
