import Vue from 'vue'
import confirm from './src/main'

const ConfirmConstructor = Vue.extend(confirm)

const Confirm = (content, title, options) => {
  return new Promise((resolve, reject) => {
    let el = document.createElement('div')
    const confirm_dom = new ConfirmConstructor({
      el,
    })

    // 第二个参数是否 options 对象
    const without_title = title instanceof Object
    if (without_title) {
      confirm_dom.options = title
    } else {
      confirm_dom.options = options
      confirm_dom.title = title
      confirm_dom.$el.id = options.id
    }

    if (confirm_dom.options && confirm_dom.options.showNotRemind) {
      const showRemind = localStorage.getItem(`confirm-${confirm_dom.options.localStorageKey}`)
      if (showRemind === 'true') {
        resolve()
        return
      }
    }

    confirm_dom.content = content
    document.body.appendChild(confirm_dom.$el)
    confirm_dom.startConfirmCountdown()
    const timer = setTimeout(() => {
      confirm_dom.$el.style.opacity = 1
      clearTimeout(timer)
    }, 0)

    // 移除弹框
    const removeConfirm = () => {
      confirm_dom.show = false
      confirm_dom.clearConfirmCountdownTimer()
      el = null
      document.body.removeChild(confirm_dom.$el)
      document.body.removeEventListener('keydown', EscListener)
      document.body.removeEventListener('keyup', EnterListener)
    }

    confirm_dom.clickClose = () => {
      removeConfirm()
    }

    const EscListener = (event) => {
      if (event.keyCode === 27) {
        reject() // eslint-disable-line
        removeConfirm()
      }
    }
    const EnterListener = (event) => {
      if (event.keyCode === 13) {
        resolve()
        removeConfirm()
      }
    }

    // 确认
    confirm_dom.confirm = () => {
      if(confirm_dom.disabledRemain > 0) return

      resolve()
      removeConfirm()
    }
    // 取消
    confirm_dom.cancel = () => {
      reject() // eslint-disable-line
      removeConfirm()
    }

    // 自定义按钮
    if (confirm_dom.options && confirm_dom.options.showThirdButton) {
      confirm_dom.customEvent = () => {
        // 自定义按钮回调
        if (confirm_dom.options.thirdCallback) {
          confirm_dom.options.thirdCallback()
        }
        removeConfirm()
      }
    }

    // enter触发确认
    if (confirm_dom.options && confirm_dom.options.enterToConfirm) {
      document.body.addEventListener('keyup', EnterListener)
    }

    // Esc关闭
    if (confirm_dom.options && confirm_dom.options.closeOnPressEscape) {
      document.body.addEventListener('keydown', EscListener)
    }

    // 点击蒙层关闭弹窗
    if (confirm_dom.options && confirm_dom.options.closeOnClickModal && typeof confirm_dom.options.closeOnClickModal == 'boolean') {
      confirm_dom.closeOnClickModal = (e) => {
        // 判断点击范围
        let is_modal = true
        const target_path = e.path
        target_path.forEach((item) => {
          if (item.className == 'el-dialog__body' || item.className == 'el-dialog__footer') {
            is_modal = false
          }
        })
        if (is_modal) {
          reject() // eslint-disable-line
          removeConfirm()
        }
      }
    }
  })
}
export default Confirm
