import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { get, post} from '@/api/indexUtils.js'
import VueCookies from 'vue-cookies'
import WhiteList from './whiteRouter'

function handleRouterErr (methodsList) {
  methodsList.forEach(item => {
      const ORIGIN_METHOD = VueRouter.prototype[item];

      VueRouter.prototype[item] = function (location) {
          let vm = this;
          return ORIGIN_METHOD.call(vm, location).catch(err => err);
      }
  });
}
handleRouterErr(['push', 'replace'])

Vue.use(VueRouter);
Vue.use(VueCookies)

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>  import("../views/HomeView.vue"),
    children:[
      {
        path: "/newQuote",
        name: "New quote",
        component: () =>
          import("@/views/NewQuote.vue"),
      },
      {
        path: "/myQuotes",
        name: "My Quotes",
        component: () =>
          import("@/views/MyQuotes.vue"),
      },
      {
        path: "/quotesDetail",
        name: "Quote Detail",
        component: () =>
          import("@/views/QuotesDetail.vue"),
      },
      {
        path: "/checkout",
        name: "Check out",
        component: () =>
          import("@/views/CheckOut.vue"),
      },
      {
        path: "/myOrders",
        name: "My Orders",
        component: () =>
          import("@/views/MyOrders.vue"),
      },
      {
        path: "/orderDetail",
        name: "Order detail",
        component: () =>
          import("@/views/OrderDetail.vue"),
      },
      {
        path: "/user",
        name: "My Account",
        component: () =>
          import("@/views/User.vue"),
      },
      {
        path: "/rfq",
        name: "RFQ",
        component: () =>
          import("@/views/Rfq.vue"),
      },
      {
        path: "/agreement",
        name: "Terms of Agreement",
        component: () =>
          import("@/components/login/Agreement.vue"),
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log('to', to)
  // 验证登陆状态
  const checkLoginStatus = async () => {
    const logStatus = window.$cookies.get('logStatus')
    if (!logStatus) {
      // cookie无效，需要重新登录
      store.dispatch('logoutUser');
    } else {
      const logInfo = window.$cookies.get('logInfo')
      console.log('logInfo ====', logInfo)
      const {email, first_name, last_name, id, is_verified} = logInfo
      store.dispatch('loginUser', {
        email,
        first_name,
        last_name,
        id,
        is_verified
      })
    }
    // try {
    //   const {code, message, data} = await post('/mpi/user/get-login')
    //   // console.log(code, message, data)
    //   if (!code) {
    //     const {email, first_name, last_name, id} = data?.data ? data.data : data
    //     store.dispatch('loginUser', {
    //       email,
    //       first_name,
    //       last_name,
    //       id
    //     })
    //   } else {
    //     // cookie无效，需要重新登录
    //     store.dispatch('logoutUser');
    //   }
    // } catch (error) {
    //   // cookie无效，需要重新登录
    //   store.dispatch('logoutUser');
    // }
  }
  // 验证登陆状态
  checkLoginStatus ()

  const isLogin = store.state.isLogin
  console.log('isLogin', isLogin)
  if (!isLogin && to.path == '/user' && to.query?.verification_code) {
    next()
  } else if (!isLogin && to.path != '/newQuote' && !WhiteList.includes(to.path)) {
    next({
      path: '/newQuote'
    })
  } else {
    next()
  }
})

export default router;
