/**
 * 挂载全局方法到Vue原型链
 */
import Vue from 'vue'

import { get, post} from './indexUtils'

export const install = function (Vue) {

  Vue.prototype.$get = get // 普通的get请求
  Vue.prototype.$post = post // 普通的post请求
}

install(Vue)
