<template>
  <div v-if="show" :style="options && options.overTagsView? 'z-index:9999':''" @click="closeOnClickModal" class="ak-confirm-container">
    <el-dialog :visible.sync="show" :width="Diawidth + 'px'" class="comfirm-dialog" id="ak-confirm">
      <div>
        <i v-if="options && options.showClickClose" class="iconfont el-icon-close" @click="clickClose"></i>
        <div class="title-wrapper">
          <icon-svg
            v-if="isSvg && iconClass"
            :icon-class="iconClass"
            width="24"
            height="24"
            class="ak-mg-right-10"
          />
          <i v-else class="iconfont" :style="`${iconColor ? `color: ${iconColor};` : ''}`" :class="{'el-icon-warning': !iconClass && warning, 'el-icon-warning': options && options.type == 'error', 'el-icon-success': options && options.type == 'success', 'el-icon-info': options && options.type == 'info', [iconClass]: iconClass}"></i>
          <span>{{title_display}}</span>
        </div>
        <p class="content" v-if="options && options.dangerouslyUseHTMLString" v-html="content"></p>
        <p class="content" v-else>{{content}}</p>
        <el-checkbox class="ak-pd-left-38 ak-mg-top-16" @change="handleRemindChange" v-if="options.showNotRemind" v-model="notRemind">不再提示</el-checkbox>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="!options || options.showCancelButton || (typeof options.showCancelButton != 'boolean')" @click="cancel">{{cancelText}}</el-button>
        <el-button v-if="options && options.showThirdButton" type="plain" @click="customEvent">{{(options && options.thirdButtonText) || '自定义'}}</el-button>
        <el-button v-if="!options || options.showConfirmButton || (typeof options.showConfirmButton != 'boolean')" :type="getType" @click="confirm" :disabled="disabledRemain > 0">{{confirmText}}
          <span v-if="disabledRemain > 0">({{disabledRemain}}s)</span>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'AkConfirm',
  components:{},
  props: {
    title: {
      type: String,
      default: '提示'
    },
    content: {
      type: String,
      default: '提示内容'
    },
    show: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default () {
        return {
          showCancelButton: true,
          showConfirmButton: true,
          showThirdButton: false, // 是否支持第三种按钮
          thirdCallback: null, // 第三种按钮回调函数
          cancelButtonText: '取 消',
          confirmButtonText: '确 定',
          confirmDuration: 0, // 确定按钮能点击的强制倒计时时长，秒数
          thirdButtonText: '自定义', // 第三种按钮文本
          closeOnClickModal: true, // 是否支持点击蒙层关闭
          clickStop: false, // 是否禁止事件穿透
          dangerouslyUseHTMLString: false, // 是否传入html
          type: 'warning', // 提示类型 warning | success | info | error
          closeOnPressEscape: false, // ESC 关闭
          enterToConfirm: false, // enter触发确定操作
          overTagsView: false,
          showClickClose: false, // 是否显示右上角点击关闭按钮
          showNotRemind: false, // 是否显示「不再提示的」
          localStorageKey: '',
          width: 400,
          isSvg: false
        }
      }
    }
  },
  data () {
    return {
      type_list: [
        'warning',
        'info',
        'success',
        'error'
      ],
      notRemind: false,
      disabledRemain: 0,
      countdownTimer: null,
    }
  },
  methods: {
    cancel () {},
    confirm () {},
    customEvent () {},
    closeOnClickModal (e) {
      if (this.options.clickStop) {
        e.stopPropagation();
      }
    },
    clickclobtnModal () {},
    clickClose () {},
    startConfirmCountdown(){
      const time = Number(this.options.confirmDuration)
      if(!isNaN(time) && time > 0){
        this.disabledRemain = time
        this.countdownTimer = setInterval(() => {
          this.disabledRemain --
          if(this.disabledRemain <= 0 ) this.clearConfirmCountdownTimer()
        }, 1000)
      }
    },
    clearConfirmCountdownTimer(){
      if(this.countdownTimer) clearInterval(this.countdownTimer)
    },
    handleRemindChange(val) {
      if(this.options.localStorageKey) {
        localStorage.setItem(`confirm-${this.options.localStorageKey}`, Boolean(val));
      }
    }
  },
  computed: {
    iconColor() {
      return this.options && this.options.iconColor
    },
    isSvg() {
      return this.options && this.options.isSvg
    },
    iconClass() {
      return this.options && this.options.iconClass
    },
    Diawidth () {
      let width
      if (this.options && this.options.width) {
        width = this.options.width
      } else {
        width = 400
      }
      return width
    },
    // 确认按钮的样式
    getType(){
      if(this.options && this.options.type == 'error'){
        return 'danger'
      }
      return 'primary'
    },
    // 标题兼容
    title_display () {
      const title = this.title || '提示'
      return title
    },
    // 是否为有效类型
    type_validata () {
      return this.type_list.find(item => item == this.options.type)
    },
    warning () {
      return (!this.options || !this.options.type || this.options.type == 'warning' || !this.type_validata)
    },
    cancelText () {
      const text = !this.options || !this.options.cancelButtonText ? '取 消' : this.options.cancelButtonText
      return text
    },
    confirmText () {
      const text = !this.options || !this.options.confirmButtonText ? '确 定' : this.options.confirmButtonText
      return text
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .ak-confirm-container {
  opacity: 0;
  transition: all 0.1s linear;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
::v-deep #ak-confirm.comfirm-dialog {
  .el-dialog {
    display: flex;
    flex-direction: column;
    z-index: 3000;
    margin-top: 120px !important;
    box-shadow: none;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      flex: 1;
      padding: 0 24px;
      > div {
        position: relative;
        .close-btn {
          font-size: 14px;
          position: absolute;
          top: 10px;
          right: -20px;
          // color: $--quinary-text;
          cursor: pointer;
        }
        .title-wrapper {
          padding-top: 20px;
          margin-bottom: 12px;
          display: flex;
          align-items: center;

          .iconfont {
            font-size: 24px;
            margin-right: 10px;
          }

          .lx_tip_warning {
            color: $--color-warning;
          }
          .lx_tip_success {
            color: $--color-success;
          }
          .lx_tip_delete {
            color: $--color-danger;
          }
          .lx_tip_info {
            color: $--color-primary;
          }
          span {
            font-size: 14px;
            color: $--primary-text;
            font-weight: bold;
          }
        }
        p.content {
          padding-left: 34px;
          color: $--secondary-text;
          font-size: 12px;
          min-height: 32px;
          line-height: 18px;
          span {
            color: $--color-primary-sub;
          }
          .ak-link-text {
            opacity: 1;
          }
          .blue {
            color: $--color-primary;
            opacity: 1;
          }
        }
        .content {
          padding-left: 34px;
          color: $--secondary-text;
          font-size: 12px;
          min-height: 32px;
        }
      }
      .lx_close {
        position: absolute;
        top: 14px;
        right: -8px;
        font-size: 20px;
        height: 20px;
        line-height: 22px;
        color: $--quinary-text;

        &:hover {
          color: $--color-primary;
        }
      }
    }
    .el-dialog__footer {
      border-radius: 4px;
      padding: 20px 24px;
      background-color: $--color-white;
      text-align: right;
      .dialog-footer {
        display: flex;
        justify-content: flex-end;
      }
      .el-button{
        border-radius: 4px;
      }
      .el-button+.el-button{
        margin-left: 16px;
      }
    }
  }
} 
</style>
