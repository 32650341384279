export default {
  state: {
    urlKey: {}
  },
  mutations: {
    addURLKey (state, url) {
      if (state.urlKey[url]) {
        state.urlKey[url]++
      } else {
        state.urlKey[url] = 1
      }
    }
  }
}
