let api = ''

// 开发
if (process.env.NODE_ENV == 'development') {
  api = 'https://mall.yiruijie365.xyz'
}

const config = {
  api,
}

module.exports = config
