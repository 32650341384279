import Vue from "vue";
import Vuex from "vuex";
import request from '@/store/request.js'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null, // 初始用户信息为空
    isLogin: false // 当前登陆状态
  },
  getters: {},
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.isLogin = true
    },
    clearUser(state) {
      state.user = null;
      state.isLogin = false
    },
  },
  actions: {
    loginUser({ commit }, user) {
      commit('setUser', user);
    },
    logoutUser({ commit }) {
      commit('clearUser');
    }
  },
  modules: {
    request
  }
});
