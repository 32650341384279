/**
 * 请求基类，公共方法封装及导出
 * 请求函数库, 一般使用原型链挂载的方法即可
 */
import { baseRequest } from './request'

// get 工具函数 获取数据
export function get(url, params, headers, timeout, extraSymbol) {
  return indexRequest(url, 'GET', params, headers, timeout, extraSymbol)
}

// post 工具函数 获取数据
export function post(url, params, headers, onUploadProgress = '', timeout, extraSymbol) {
  return indexRequest(url, 'POST', params, headers, onUploadProgress, timeout, extraSymbol)
}



// axios请求封装
export function indexRequest(url, method, params, head = {}, onUploadProgress, timeout, extraSymbol) {
  const headers = {
    ...head,
    // 'X-AK-Request-Id': uuid.v4(),
    // ...commonHeader()
  }
  return baseRequest('', url, method, params, headers, onUploadProgress, timeout, extraSymbol)
}
